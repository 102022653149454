"use client";

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LogOut, Menu, Moon, Settings, Store, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { usePathname } from "next/navigation";
import { useAppDispatch, useAppSelector } from "@/hooks/RTKTypedHooks";
import { logout } from "@/core/reducers/user.reducer";
import { User } from "@prisma/client";
import { BuyButton } from "./buy-button.client";

export default function NavigationBar({ show = true }: { show?: boolean }) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const pathName = usePathname();
  const { setTheme, theme } = useTheme();
  if (pathName.includes("session")) {
    show = false;
  }
  const handleLogout = async () => {
    dispatch(logout());
  };

  return (
    show && (
      <div className='ligth:bg-white dark:bg-black'>
        <div className='container'>
          <NavigationMenu className='flex  min-w-full flex-col items-center justify-between py-4 md:flex-row'>
            <div className='relative mb-6 lg:mb-0'>
              <div className='absolute inset-y-0 w-full bg-[linear-gradient(to_right,#F87BFF,#FB92CF,#FFDD9B,#C2F0B1,#2FD8FE)] blur-md' />

              <Link href='/' passHref className=' size-full '>
                <h1 className=' relative cursor-pointer rounded-lg bg-blue-50 p-3 text-5xl font-bold dark:bg-black'>
                  Fluentica
                </h1>
              </Link>
            </div>

            <NavigationMenuList className='gap-6 lg:gap-x-4'>
              {user && user.id !== null && (
                <div className={`${user.remaining_time ?"flex flex-col":"flex flex-row items-center gap-5"}`}>
                  <p>Bienvenue {user.name}</p>
                  {user.remaining_time ? (
                    <p>
                      Il vous reste{" "}
                      {Math.round((user.remaining_time / 10) * 100)}% de crédit
                    </p>
                  ) : (
                    <BuyButton />
                  )}
                </div>
              )}
              {/* Theme */}

              <NavigationMenuItem>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant='outline' size='icon'>
                      <Sun className='size-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' />
                      <Moon className='absolute size-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100' />
                      <span className='sr-only'>Toggle theme</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align='end'>
                    <DropdownMenuItem onClick={() => setTheme("light")}>
                      Light
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setTheme("dark")}>
                      Dark
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setTheme("system")}>
                      System
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </NavigationMenuItem>
              {pathName === "/" && (
                <Link
                  href='#FAQ'
                  className='transition dark:text-white dark:text-opacity-60 dark:hover:text-opacity-100'
                >
                  F.A.Q
                </Link>
              )}
              {/* User toggle*/}
              {user && user.id !== null && (
                <>
                  <NavigationMenuItem>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant='outline' size='icon'>
                          <Menu className='size-[1.2rem] rotate-0 scale-100 transition-all ' />
                          <span className='sr-only'>Toggle theme</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        align='end'
                        className=' border-blue-400/50 dark:border-white/20'
                      >
                        <Link href='/store' passHref>
                          <DropdownMenuItem className='text-lg'>
                            <Store className='mr-2 size-[1.2rem] rotate-0 scale-100 transition-all' />{" "}
                            Store
                          </DropdownMenuItem>
                        </Link>
                        <Link href='/settings' passHref>
                          <DropdownMenuItem className='text-lg'>
                            <Settings className='mr-2 size-[1.2rem] rotate-0 scale-100 transition-all' />{" "}
                            Paramètres
                          </DropdownMenuItem>
                        </Link>
                        <DropdownMenuItem
                          onClick={handleLogout}
                          className='text-lg'
                        >
                          <LogOut className='mr-2 size-[1.2rem] rotate-0 scale-100 transition-all ' />{" "}
                          Déconnexion
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </NavigationMenuItem>
                </>
              )}
              {!user.id && pathName === "/" && (
                <NavigationMenuItem>
                  <Link href='/auth/login' passHref>
                    <Button className='rounded-lg px-4 py-2 transition hover:scale-[1.03] dark:bg-white dark:text-black'>
                      Essai gratuit
                    </Button>
                  </Link>
                </NavigationMenuItem>
              )}
            </NavigationMenuList>
          </NavigationMenu>
        </div>
      </div>
    )
  );
}
