"use server";
import { getUserByEmail } from "@/actions/user/getUserByEmail";
import { LoginSchema } from "@/schemas/auth";

import { z } from "zod";
import bcrypt from "bcryptjs";
import { generateToken } from "@/lib/token";
import { User } from "@prisma/client";
import { setCookies } from "../setCookies";
import { actionClient } from "@/lib/safe-action";

export type LoginSuccess = {
  user: User;
  message: string;
};

export type LoginFailure = {
  failure: string;
};

export type LoginResponse = LoginSuccess | LoginFailure;

export const login = actionClient
  .schema(LoginSchema)
  .action(async ({ parsedInput: values }): Promise<LoginResponse> => {
    /* const validatedFields = parsedInput;
    if (!validatedFields.success) throw new Error("Champs non valides"); */

    const { email, password } = values;
    const existingUserDaTa = await getUserByEmail(email);
   const existingUser = existingUserDaTa?.data;
    if (!existingUser) return { failure: "Cet utilisateur n'éxiste pas" };
    const isPasswordValid = await bcrypt.compare(
      password,
      existingUser.password!,
    );

    if (!isPasswordValid) {
      throw new Error("Email ou mot de passe incorrect");
    }

    if (!existingUser.emailVerified) {
      throw new Error("Email non vérifié");
    }

    const token = await generateToken(existingUser.email);

    setCookies({
      tokenTitle: "token",
      token,
      config: {
        httpOnly: true,
        secure: process.env.NODE_ENV === "production",
        maxAge: 30 * 24 * 60 * 60 * 1000, //1 mois
        path: "/", // Root path
        sameSite: "lax",
      },
    });
    return { user: existingUser, message: "Connexion réussie" };
  });

/* export const login = async (
  values: z.infer<typeof LoginSchema>,
): Promise<{ user: User; message: string }> => {
  const validatedFields = LoginSchema.safeParse(values);
  if (!validatedFields.success) throw new Error("Champs non valides");
  const { email, password } = validatedFields.data;
  const existingUser = await getUserByEmail(email);
  if (!existingUser) throw new Error("Cet utilisateur n'éxiste pas");
  const isPasswordValid = await bcrypt.compare(
    password,
    existingUser.password!,
  );
  
  if (!isPasswordValid) {
    throw new Error("Email ou mot de passe incorrect");
  }

  if (!existingUser.emailVerified) {
    throw new Error("Email non vérifié");
  }

  const token = await generateToken(existingUser.email);

  setCookies("token", token, {
    httpOnly: true,
    secure: process.env.NODE_ENV === "production",
    maxAge: 30 * 24 * 60 * 60 * 1000, //1 mois
    path: "/", // Root path
    sameSite: "lax",
  });

  return { user: existingUser, message: "Connexion réussie" };
}; */
